<template>
  <v-container fluid class="pa-0">
    <v-dialog 
      transition="dialog-top-transition"
      v-model="dialog" 
      persistent 
      max-width="80%">
      <v-card>
        <v-card-title class="title">Signature</v-card-title>
        <v-card-text class="subtitle-1" v-text="message.text"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class="white--text"
            depressed
            v-if="message.type != 'wait'"
            @click="dialog_action()"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col sm="12" style="position: relative;">
        <div class="buttons-area">
          <v-btn
            color="grey darken-1"
            class="white--text equalBtn"
            x-large
            depressed
            @click="clearSignature()"
            >Clear</v-btn
          >
          <v-btn
            color="orange darken-1"
            class="white--text equalBtn"
            x-large
            depressed
            @click="saveSignature()"
            >Save</v-btn
          >
        </div>
        <v-form ref="signatureform">
          <v-row no-gutters class="mx-5">
            <v-col sm="12" cols="12">
              <v-text-field
                class="my-5"
                label="Received By"
                v-model="name_pod"
                id="name_pod"
                :rules="[rules.required]"
                :value="this.name_pod"
                dense
                color="orange"
                onkeyup="this.value = this.value.toUpperCase();"
              ></v-text-field>
            </v-col>
            <v-col sm="12" cols="12">
              <v-text-field
                class="my-5"
                label="Email Address"
                v-model="email_pod"
                id="email_pod"
                :rules="[rules.email]"
                :value="this.email_pod"
                dense
                color="orange"
                onkeyup="this.value = this.value.toUpperCase();"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mx-5">
            <v-col sm="6" cols="6">
              <v-dialog
                transition="dialog-top-transition"
                ref="time_in_dialog"
                v-model="time_in_modal"
                :return-value.sync="time_in"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="my-5"
                    label="Time In"
                    v-model="time_in"
                    :rules="[rules.required]"
                    readonly
                    dense
                    color="orange"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="time_in_modal"
                  v-model="time_in"
                  :max="time_out"
                  format="24hr"
                  color="orange lighten-1"
                  header-color="orange"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    class="red--text text--darken-1"
                    text
                    @click="
                      $refs.time_in_dialog.save('');
                      time_in_modal = false;
                    "
                    >CANCEL</v-btn
                  >
                  <v-btn
                    class="green--text text--darken-1"
                    text
                    @click="$refs.time_in_dialog.save(time_in)"
                    >SET TIME IN</v-btn
                  >
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-col sm="6" cols="6">
              <v-dialog
                transition="dialog-top-transition"
                ref="time_out_dialog"
                v-model="time_out_modal"
                :return-value.sync="time_out"
                persistent
                width="290px"
                disabled
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="my-5"
                    label="Time Out"
                    v-model="time_out"
                    :rules="[rules.required]"
                    readonly
                    dense
                    color="orange"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="time_out_modal"
                  v-model="time_out"
                  format="24hr"
                  color="orange lighten-1"
                  header-color="orange"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    class="red--text text--darken-1"
                    text
                    @click="
                      $refs.time_out_dialog.save('');
                      time_out_modal = false;
                    "
                    >CANCEL</v-btn
                  >
                  <v-btn
                    class="green--text text--darken-1"
                    text
                    @click="$refs.time_out_dialog.save(time_out)"
                    >SET TIME OUT</v-btn
                  >
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-form>
        <fieldset class="sign-panel-border ma-1 mt-2 pa-1">
          <legend>Sign here</legend>
          <VueSignature widht="100%" height="300px" ref="signaturePad" />
        </fieldset>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import VueSignature from "vue-signature-pad";
import moment from "moment";

Vue.use(VueSignature);

export default {
  name: "Signature",
  mounted: function() {
    if (this.currentOrder.details.load_information.email_pod != null){
      this.name_pod = this.currentOrder.details.load_information.email_pod[1];
      this.email_pod = this.currentOrder.details.load_information.email_pod[2];
    }
    this.time_in = this.getTimeInByDriver()
  },  
  computed: {
    message() {
      return this.$store.getters.message;
    },
    currentOrder() {
      return this.$store.getters.currentOrders.find((move) => {
        return move.movement_id == this.$route.params.mid;
      });
    }, 
  
    /*
    max_time_out_23h(){
      return this.max_time_out(this.time_in);
    }*/
  },
  methods: {
    /*max_time_out(time_in){
      var dateObj = new Date();
      var dateStr = dateObj.toISOString().split('T').shift();
      return moment(moment(dateStr + ' ' + time_in).toDate()).add(23, 'hours').format('HH:mm');
    },*/
    getTimeInByDriver() {
      return this.$store.getters.timeInByDriver;
    },    
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
    },
    saveSignature() {
      if (this.$refs.signatureform.validate()) {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        if (isEmpty == true) {
          this.$store.dispatch("setMessage", {
            type: "error",
            text: "The signature is required",
          });
        } else {
          this.$store.dispatch("setMessage", {
            type: "wait",
            text: "Saving ...",
          });
          this.$store.dispatch("saveSignature", {
            move_id: this.$route.params.mid,
            name_pod: this.name_pod,
            email_pod: this.email_pod,
            time_in: this.time_in,
            time_out: this.time_out,
            signature_pod: data,
            dispatched_status: this.currentOrder.details.route_information.dispatched_status,
          });

          //console.log(isEmpty);
          //console.log(data);
        }
        this.dialog = true;
      }
    },
    dialog_action() {
      if (this.message.type == "success") {
        this.$store.dispatch("clearCurrentOrder");
        this.$router.push("./../orders");
      } else {
        this.dialog = false;
      }
    },
  },
  components: {
    VueSignature,
  },
  data: () => ({
    dialog: false,
    status: {},
    name_pod: "",
    email_pod: null,
    time_in: null,
    time_out: moment().format('HH:mm'),
    time_in_modal: false,
    time_out_modal: false,
    rules: {
      required: (value) => !!value || "Required",
      //min3: (v) => v.length >= 3 || "Min 3 characters",
      email: (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Invalid email address",
    },
  }),
};
</script>
<style scoped>
.buttons-area {
  position: relative;
  top: 2px;
  margin-bottom: 40px;
  width: 100%;
  height: 50px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.equalBtn {
  border-radius: 0px;
  width: 50%;
  height: -webkit-fill-available !important;
  font-size: 19px;
}
.sign-panel-border {
  border: 1px solid #ccc;
  max-height: 330px;
}
</style>
