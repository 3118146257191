<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col sm="12" style="position: relative;">
        <div class="camera-button">
            <button type="button" class="button is-rounded" :class="{ 'is-primary' : !isCameraOpen, 'is-danger' : isCameraOpen}" @click="toggleCamera">
                <span v-if="!isCameraOpen">Open Camera</span>
                <span v-else>Close Camera</span>
            </button>
        </div>
        
        <div v-show="isCameraOpen && isLoading" class="camera-loading">
          Loading ...
        </div>
        
        <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
            
            <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
            
            <video v-show="!isPhotoTaken" ref="camera" :width="390" :height="292.5" autoplay></video>
            
            <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="390" :height="292.5"></canvas>
        </div>
        
        <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
            <button type="button" class="button" @click="takePhoto">
            <img src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png">
            </button>
        </div>        
      </v-col>
    </v-row>
    <v-card class="mx-auto my-3" max-width="90%" v-if="isPhotoTaken">
      <v-row no-gutters class="px-2 py-4">
        <div
          v-for="container in containers"
          :key="container.id"
          class="py-2"
        >
          <v-text-field
            :id="container.id"
            :value="container.number != '___________' ? container.number : ''"
            :maxlength="11"
            label="Container Number"
            dense
            color="orange"
            disabled
          ></v-text-field>
        </div>
        <v-select
          label="Type"
          v-model="selected_type"                             
          :items="image_types"
          :rules="[rules.required]"
          dense
          color="orange"
        ></v-select>           
        <v-text-field
          name="comments"
          label="Comments"
          value=""
          hint="Photo description"
          color="orange"
        ></v-text-field>
      </v-row>
      <v-row no-gutters class="px-2 py-4 d-flex justify-center align-center">
        <div v-if="isPhotoTaken && isCameraOpen" class="camera-download">
          <v-btn
            color="orange darken-1"
            class="white--text updateBtn"
            small
            depressed
            id="downloadPhoto" 
            download="my-photo.jpg"
            @click="downloadImage"
            >Send image</v-btn
          >
        </div>
      </v-row>
    </v-card>    
    <v-layout row v-if="message.text">
      <v-flex xs12 sm6 px-1 ma-0>
        <app-alert
          :textAlert="message.text"
          :typeAlert="message.type"
          @dismissed="onDismissed()"
        ></app-alert>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: "Camera",
  beforeMount() {
    this.containers = this.currentOrder.details.load_information.containers;
    this.toggleCamera();
  },  
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: '#',
      containers: [],
      comments: "", 
      image_types: ['POD', 'EIR', 'W/O', 'OTHER'],
      selected_type: "",   
      rules: {
        required: (value) => !!value || "Required",
      },        
    }
  },
  computed: {
    message() {
      return this.$store.getters.message;
    },
    driver_allow_updates() {
      return this.$store.getters.user.allow_updates;
    },
    currentOrder() {
      return this.$store.getters.currentOrders.find((move) => {
        return move.movement_id == this.$route.params.mid;
      });
    },
    auth_token() {
      return this.$store.getters.token;
    },
  },  
  methods: {
    toggleCamera() {
      if(this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    
    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
				audio: false,
				video: true
			});

        // eslint-disable-next-line no-unused-vars
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then(stream => {
                this.isLoading = false;
                this.$refs.camera.srcObject = stream;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                this.$store.dispatch("setMessage", {
                    type: "error",
                    text: "You can't use camera on this device",
                })
            });
        
    },
    
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

			tracks.forEach(track => {
				track.stop();
			});
    },
    
    takePhoto() {
      if(!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }
      
      this.isPhotoTaken = !this.isPhotoTaken;
      
      const context = this.$refs.canvas.getContext('2d');
      context.drawImage(this.$refs.camera, 0, 0, 390, 292.5);
    },
    
    downloadImage() {
    //   const download = document.getElementById("downloadPhoto");
      const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
    .replace("image/jpeg", "image/octet-stream");
        // download.setAttribute("href", canvas);
      this.$store.dispatch("savePhoto", {
        move_id: this.$route.params.mid,
        photo: canvas,
        comments: this.comments,
      });      
      },
    onDismissed() {
      this.$store.dispatch("clearMessage");
    },    
  }  
};
</script>
<style scoped>
body {
  display: flex;
  justify-content: center;
}
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}
.web-camera-container .camera-button {
  margin-bottom: 2rem;
}
.web-camera-container .camera-box .camera-shutter {
  opacity: 0;
  width: 390px;
  height: 292.5px;
  background-color: #fff;
  position: absolute;
}
.web-camera-container .camera-box .camera-shutter.flash {
  opacity: 1;
}
.web-camera-container .camera-shoot {
  margin: 1rem 0;
}
.web-camera-container .camera-shoot button {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.web-camera-container .camera-shoot button img {
  height: 35px;
  object-fit: cover;
}
.web-camera-container .camera-loading {
  overflow: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  min-height: 150px;
  margin: 3rem 0 0 -1.2rem;
}
.web-camera-container .camera-loading ul {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 999999;
  margin: 0;
}
.web-camera-container .camera-loading .loader-circle {
  display: block;
  height: 14px;
  margin: 0 auto;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  padding: 0;
}
.web-camera-container .camera-loading .loader-circle li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  padding: 0;
  position: relative;
  margin: 0 0 0 4px;
  background: #999;
  animation: preload 1s infinite;
  top: -50%;
  border-radius: 100%;
}
.web-camera-container .camera-loading .loader-circle li:nth-child(2) {
  animation-delay: 0.2s;
}
.web-camera-container .camera-loading .loader-circle li:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes preload {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

</style>